
.modal-title[data-v-dadf0a9c] {
  flex-grow: 1;
}


#register-form[data-v-48f9360b] {
  text-align: left;
}

